import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

const prod: boolean = process.env.NODE_ENV === "production";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  // Convert to responsive breakpoints
  let breakpoint = "sm";
  if (width >= 640) breakpoint = "md";
  if (width >= 768) breakpoint = "lg";
  if (width >= 1024) breakpoint = "xl";
  if (width >= 1280) breakpoint = "2xl";

  const mobile = width < 1025;

  return {
    width,
    height,
    breakpoint,
    mobile,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const handleResize = useDebouncedCallback(() => {
    setWindowDimensions(getWindowDimensions());
  }, 300);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return windowDimensions;
}

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState<
    "up" | "down" | "static" | "top"
  >("top");
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    function handleScroll() {
      const st = window.screenY || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        setScrollDirection("down");
        clearTimeout(timeout);
      } else if (st < lastScrollTop) {
        setScrollDirection("up");
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        if (st <= 0) {
          setScrollDirection("top");
        } else {
          setScrollDirection("static");
        }
      }, 100);

      setLastScrollTop(st <= 0 ? 0 : st);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return { scrollDirection };
}

export function useRefresh() {
  useEffect(() => {
    const interval = setInterval(
      () => {
        if (prod) {
          fetch(`${process.env.NEXT_PUBLIC_O_AUTH_URL}/touch.asp`);
          fetch(`${process.env.NEXT_PUBLIC_O_AUTH_URL}/RefreshSessionToken`);
        }
      },
      30 * 60 * 1000,
    );

    return () => clearInterval(interval);
  }, []);
}
